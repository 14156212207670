.drawer-paper {
  position: relative;
}

.drawer-menu .MuiPaper-root {
  z-index: 11;
  background: #f32836;
  padding-top: 50px;
  padding-bottom: 60px;
  @media (max-width: 1024px) {
    transform: translateX(-280px);
    -webkit-transform: translateX(-280px);
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    z-index: 111;
  }
}
.MuiDrawer-paper {
  overflow-x: hidden;
}
.MuiDrawer-paperAnchorDockedLeft {
  border: 0 !important;
}

#app-drawer {
  flex-direction: column;

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: 35px 0;
    justify-content: center;
    width: 100%;
    position: relative;
    z-index: 11;
    cursor: pointer;

    img {
      width: 60%;
      margin-right: 0px;

      @media (max-width: 768px) {
        width: 130px;
      }
    }
  }

  .MuiList-padding {
    width: 100%;
    padding-top: 30px;
    padding-left: 20px;
    overflow: hidden;
  }
}

.drawer-view {
  width: 280px;
  height: 100%;
  left: 0;
  top: 0;
  position: relative;
  z-index: 11;
  .top-shape {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }
  .bottom-shape {
    transition: none;
    position: absolute;
    left: -1px;
    bottom: 0;
    width: 85%;
    pointer-events: none;
  }
}

.list-item {
  margin: 0px 0 15px;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  padding: 10px 0 !important;
  cursor: pointer;
  border: 1px solid transparent;

  transition: all 0.5s ease-in-out;

  span {
    color: #fff;
  }

  &.active-tab {
    background: #fff;
    border-radius: 15px 0 0 15px;

    span {
      color: #f42634;
    }

    &:hover {
      border: 1px solid #fff;
      border-radius: 15px 0 0 15px;

      span {
        color: #f42634;
      }
    }
  }

  &:hover {
    border: 1px solid #fff;
    border-radius: 15px 0 0 15px;

    span {
      color: #fff;
    }
  }
}
.list-tab {
  color: rgb(235, 157, 157);
  text-align: left;
  align-items: center;
  img {
    height: auto;
  }
}
.MuiListItemIcon-root {
  justify-content: center;

  img {
    width: 22px;
    height: auto;
  }
}
.close-image {
  cursor: pointer;
  @media screen and (max-width: 820px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: auto;
    position: absolute;
    padding: 10px;
    z-index: 11;
    top: 1%;
    right: 2%;
  }
}

.logout_menu {
  position: fixed;
  bottom: 0;
  width: 280px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px;
  padding-left: 20px;
  cursor: pointer;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  @media (max-height: 560px), screen and (orientation: portrait) {
    position: static;
  }
  label {
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
    cursor: pointer;
    font-family: inherit;
  }
  i {
    min-width: 56px;
    display: inline-block;
    text-align: center;
  }
  img {
    width: 24px;
    height: auto;
  }
}
// .overlay_nav {
//   z-index: 11;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5);
// }
