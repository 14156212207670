.drop-down {
  width: auto;
  display: flex;
  padding-left: 0;
  align-items: center;
  position: relative;
  flex-direction: row;
  word-break: keep-all;
  // @media (max-width:767px){
  //     display: block;
  // }
  label {
    font-weight: 500;
    margin-right: 10px;
    text-align: right;
    white-space: nowrap;
    word-break: keep-all;
    @media (max-width: 1170px) {
      min-width: initial;
    }
    @media (max-width: 575px) {
      min-width: 60px;
      text-align: left;
    }
  }
}

.drop-down {
  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #4d4d4f;
      border-width: 1px;
    }
  }
  .MuiOutlinedInput-input {
    padding: 8px 14px;
    height: 24px;
  }
  .MuiSelect-select {
    border-color: #dbdbdb;
    border-width: 1px;
    border-style: solid;
  }
}

.MuiPopover-root {
    .Mui-checked {
      svg {
        path {
          fill: #f32836;
        }
      }    
    }
    .MuiCheckbox-root {
       padding-left: 0;
    }
   .MuiMenuItem-root {
     border-bottom: 1px solid #eee !important;
   }
   .MuiIconButton-colorSecondary{
      &:hover, &:focus {
        background: transparent!important;
      }
    }
}
.MuiMenu-paper {
.MuiListItemText-root {
word-break: break-word;
white-space: normal;
line-height: 2px;
.MuiTypography-root {
line-height:1.1
}
}
}
